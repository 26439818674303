"use client";

import Link from "next/link";
import { useMemo, useState } from "react";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { SearchDataType } from "../../../../../../types/common";
import { format } from "date-fns";
import { TitleText } from "@/components/common/texts/title-text";
import { LabelText } from "@/components/common/texts/label-text";
import toast from "react-hot-toast";
import { useRouter } from "next/navigation";
import * as gtag from "../../../../../../../lib/gtag";
import { MobileSearchIcon } from "../../../../../../../assets/mobile-search-icon";

export const MobileSearchBar = ({
  isListingsPage,
}: {
  isListingsPage?: boolean;
}) => {
  const { push } = useRouter();

  const [searchData, setSearchData] = useState<SearchDataType>({
    guests: {
      noOfChildren: 0,
      noOfAdults: 0,
      noOfPets: 0,
      noOfTotalGuests: 0,
    },
    checkInDate: null,
    checkOutDate: null,
    city: "",
  });

  const handleSearch = () => {
    if (!searchData.city) {
      toast.error("Please select city");
      return;
    }
    gtag.event({
      action: "search_click_mobile",
      category: "engagement",
      label: "Search Button",
      value: 1,
    });

    push(`/listings?city=${searchData.city}`);
  };

  const isDates = useMemo(
    () => (searchData.checkInDate && searchData.checkOutDate ? true : false),
    [searchData.checkInDate, searchData.checkOutDate]
  );

  const isCity = useMemo(
    () => (searchData.city ? true : false),
    [searchData.city]
  );

  const isGuests = useMemo(
    () => (searchData.guests.noOfTotalGuests ? true : false),
    [searchData.guests.noOfTotalGuests]
  );

  useLocalStorageSearchData({ setSearchData });

  return (
    <div
      className={`${
        isListingsPage && "mobile:w-full"
      } z-[430] bg-white relative border-[0.5px] border-secondaryG2 rounded-[2.5px] p-2.5 flex items-center`}
    >
      <Link href="?mobileSearchBar=true" className="flex flex-col w-[80%]">
        <TitleText
          className={`${
            !isCity && !isDates && !isGuests
              ? "text-primaryB7"
              : "text-neutralN6"
          } !leading-[18px]`}
        >
          Where to next?
        </TitleText>
        <LabelText
          className={`${
            !isDates || !isCity || !isGuests
              ? "text-neutralN6"
              : "text-primaryB7"
          } !leading-[18px]`}
        >
          <span className={!isCity ? "text-neutralN6" : "text-primaryB7"}>
            {isCity ? searchData.city : "destination"}
          </span>{" "}
          •{" "}
          <span className={!isDates ? "text-neutralN6" : "text-primaryB7"}>
            {isDates
              ? `${format(searchData.checkInDate!, "dd MMM")} - ${format(
                  searchData.checkOutDate!,
                  "dd MMM"
                )}`
              : "dates"}{" "}
          </span>
          •{" "}
          <span className={!isGuests ? "text-neutralN6" : "text-primaryB7"}>
            {isGuests
              ? `${searchData.guests.noOfTotalGuests} guests`
              : "guests"}
          </span>
        </LabelText>
      </Link>
      <button
        onClick={handleSearch}
        className={`absolute -translate-y-1/2 top-1/2 right-2.5 self-end ml-auto cursor-pointer rounded-[5px] h-[34px] w-[34px] flex items-center justify-center ${
          !isListingsPage ? "bg-primaryB6" : "bg-primaryB4"
        }`}
      >
        <MobileSearchIcon />
      </button>
    </div>
  );
};
