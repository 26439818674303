"use client";

import ModalOverlay from "@/components/modal";
import { SelectDestination } from "@/components/user-data-inputs/select-destination";
import { useCallback, useEffect, useState } from "react";
import { SelectDate } from "@/components/user-data-inputs/select-date";
import { AddGuests } from "@/components/user-data-inputs/add-guests";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { Guests, SearchDataType } from "@/types/common";
import {
  addSearchDataInLocalStorage,
  clearSearchData,
} from "@/lib/search-data";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { HeadlineText } from "@/components/common/texts/headline-text";
import { LabelText } from "@/components/common/texts/label-text";
import { PrimaryActiveButton } from "@/components/common/buttons/primary-active-button";
import * as gtag from "./../../../../../../../lib/gtag";

export const MobileSearchBarModal = () => {
  const [searchData, setSearchData] = useState<SearchDataType>({
    guests: {
      noOfChildren: 0,
      noOfAdults: 0,
      noOfPets: 0,
      noOfTotalGuests: 0,
    },
    checkInDate: null,
    checkOutDate: null,
    city: "",
  });
  const [isDestinationOpen, setIsDestinationOpen] = useState(false);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  const { push } = useRouter();

  const checkInQuery = searchData.checkInDate
    ? format(searchData.checkInDate, "dd MMM")
    : "";

  const checkoutQuery = searchData.checkOutDate
    ? format(searchData.checkOutDate, "dd MMM")
    : "";

  const guestsQuery = searchData.guests.noOfTotalGuests
    ? `${searchData.guests.noOfTotalGuests} guests`
    : "";

  const handleSearch = () => {
    if (!searchData.checkInDate || !searchData.checkOutDate) {
      toast.error("Please select your travel dates");
      return;
    }

    if (!searchData.city) {
      toast.error("Please select your destination");
      return;
    }

    if (!searchData.guests.noOfTotalGuests) {
      toast.error("Please add guests");
      return;
    }

    push(
      `/listings?city=${searchData.city}&checkInDate=${checkInQuery}&checkOutDate=${checkoutQuery}&guests=${guestsQuery}`
    );

    addSearchDataInLocalStorage(searchData);
  };

  const handleCity = (selectedCity: string) => {
    setSearchData((prev) => {
      return { ...prev, city: selectedCity };
    });
    if (selectedCity) {
      setIsDestinationOpen(false);
      setIsDatesModalOpen(true);
    }
  };

  const handleDates = (date: [null | Date, null | Date]) => {
    const [startDate, endDate] = date;
    setSearchData((prev) => {
      return { ...prev, checkInDate: startDate, checkOutDate: endDate };
    });
    if (startDate && endDate) {
      setIsGuestsOpen(true);
    }
  };

  const handleGuests = useCallback((guests: Guests) => {
    gtag.event({
      action: `guest_number_change_half`,
      category: "user input",
      label: "User trying to change guest number",
      value: 1,
    });
    setSearchData((prev) => {
      return { ...prev, guests };
    });
  }, []);

  const clearAll = () => {
    clearSearchData({ setSearchData });
  };

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth > 475 && push("/");
    };

    if (window.innerWidth > 475) {
      push("/");
      return;
    }
    window.addEventListener("resize", handleResize);

    return window.removeEventListener("resize", handleResize);
  }, [push]);

  useLocalStorageSearchData({ setSearchData });

  return (
    <ModalOverlay
      onClose={() => {}}
      className="hidden mobile:block !rounded-none fixed !h-screen !w-screen mobile:!w-screen !top-0 !left-0 !overflow-x-hidden !py-0 !px-0 !mx-0"
    >
      <div className="mt-[70px] h-full w-full relative px-5 py-[30px]">
        <div className="flex flex-col gap-y-3">
          <HeadlineText className="text-primaryB7 !normal-case mobile:!text-[25px] -mt-[30px]">
            Start booking your next stay
          </HeadlineText>

          <SelectDestination
            destination={searchData.city}
            handleSelectCity={handleCity}
            isDestinationOpen={isDestinationOpen}
            setIsDestinationOpen={setIsDestinationOpen}
          >
            <LabelText className="text-primaryB7 mobile:!text-[12px]">
              Where to?
            </LabelText>
          </SelectDestination>

          <SelectDate
            checkOutDate={searchData.checkOutDate}
            checkInDate={searchData.checkInDate}
            handleDates={handleDates}
            setIsDatesOpen={setIsDatesModalOpen}
            isDatesOpen={isDatesModalOpen}
          >
            <LabelText className="text-primaryB7 mobile:!text-[12px]">
              booking dates?
            </LabelText>
          </SelectDate>

          <AddGuests
            isGuestsOpen={isGuestsOpen}
            setIsGuestsOpen={setIsGuestsOpen}
            guests={searchData.guests}
            handleGuests={handleGuests}
          >
            <LabelText className="text-primaryB7 mobile:!text-[12px]">
              How many of you
            </LabelText>
          </AddGuests>
        </div>
        <div
          className={`${
            isDatesModalOpen && "-z-10"
          } px-[30px] w-full fixed bottom-24 left-1/2 -translate-x-1/2 flex items-center justify-between py-4 border-t-[0.5px] border-secondaryG2`}
        >
          <button onClick={clearAll}>
            <LabelText className="text-primaryB7 mobile:!font-normal mobile:!text-[14px]">
              Clear filters
            </LabelText>
          </button>
          <button onClick={handleSearch}>
            <PrimaryActiveButton
              link="#"
              onClick={(event) => event.preventDefault()}
            >
              Search stays
            </PrimaryActiveButton>
          </button>
        </div>
      </div>
    </ModalOverlay>
  );
};
