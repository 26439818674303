"use client";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./select-date.css";
import { Lexend_Deca } from "next/font/google";
import { format } from "date-fns";
import { BodyText } from "../common/texts/body-text";
import toast from "react-hot-toast";
import * as gtag from "./../../../lib/gtag";

export const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

interface Props {
  handleDates: (dates: [null | Date, null | Date]) => void;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  children?: React.ReactNode;
  bookedDays?: Date[];
  setIsDatesOpen: Dispatch<SetStateAction<boolean>>;
  isDatesOpen: boolean;
}

export const SelectDate = ({
  handleDates,
  children,
  bookedDays,
  checkInDate,
  checkOutDate,
  setIsDatesOpen,
  isDatesOpen,
}: Props) => {
  const datePickerRef = useRef<DatePicker>(null);
  const [bookedMaxDate, setBookedMaxDate] = useState<null | Date>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [bookedDaysArray, setBookedDaysArray] = useState<Date[]>([]);
  const prevDatesRef = useRef<{ checkIn: Date | null; checkOut: Date | null }>({
    checkIn: null,
    checkOut: null,
  });

  const getPreviousDate = (date: Date): Date => {
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate;
  };

  const getNextDate = (date: Date): Date => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  };

  const isBlocked = (date: Date): boolean => {
    if (!bookedDays) return false;

    const bookedSet = new Set(
      bookedDays.map((day) => new Date(day).toISOString().split("T")[0])
    );

    const formattedDate = new Date(date).toISOString().split("T")[0];

    return bookedSet.has(formattedDate);
  };

  // Custom function to handle valid date range selection
  // const handleValidDateRangeSelection = (
  //   start: Date | null,
  //   end: Date | null
  // ) => {
  //   if (start && end) {
  //     // If we have both start and end dates, close the date picker
  //     setTimeout(() => {
  //       setIsOpen(false);
  //     }, 50); // Small timeout to ensure the state is updated properly
  //   }
  // };

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    gtag.event({
      action: `date_change_half`,
      category: "data_input",
      label: "User trying to change date",
      value: 1,
    });

    if (
      checkInDate &&
      start &&
      !end &&
      checkInDate.getTime() === start.getTime()
    ) {
      setBookedMaxDate(null);
      handleDates([null, null]);
      return;
    }

    if (start && end && start.getTime() === end.getTime()) {
      setBookedMaxDate(null);
      handleDates([null, null]);
      return;
    }

    if (
      start &&
      isBlocked(start) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(start)) && // Previous date is not blocked or excluded
      isBlocked(getNextDate(start))
    ) {
      handleDates([null, null]);
      toast.error("This date is already booked");
      return;
    }

    if (
      start &&
      end &&
      isBlocked(end) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(end)) && // Previous date is not blocked or excluded
      isBlocked(getNextDate(end))
    ) {
      handleDates([start, end]);
      // handleValidDateRangeSelection(start, end);
      return;
    }

    if (
      start &&
      end &&
      isBlocked(end) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(end)) && // Previous date is not blocked or excluded
      !isBlocked(getNextDate(end))
    ) {
      setBookedMaxDate(null);
      handleDates([start, end]);
      // handleValidDateRangeSelection(start, end);
      return;
    }

    const sortedBookedDays = bookedDaysArray.sort(
      (a, b) => a.getTime() - b.getTime()
    );

    const nextBookedDate = sortedBookedDays.find(
      (bookedDate) => start && bookedDate > start
    );

    if (start && nextBookedDate) {
      setBookedMaxDate(nextBookedDate);

      if (end && end >= nextBookedDate) {
        toast.error(
          "Selected date range includes blocked dates. Adjusting end date."
        );
      } else {
        //@ts-ignore
        handleDates([start, end]);
        // handleValidDateRangeSelection(start, end);
      }
    } else if (start) {
      //@ts-ignore
      handleDates([start, end]);
      // handleValidDateRangeSelection(start, end);
    }
  };

  //@ts-ignore
  const dayClassName = (date) => {
    const today = new Date();
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1);

    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);

    // Reset time to ensure accurate comparison
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    previousDate.setHours(0, 0, 0, 0);
    nextDate.setHours(0, 0, 0, 0);

    if (date < today) {
      return "past-date"; // Class for past dates
    }

    bookedDaysArray.forEach((bookedDay) => bookedDay.setHours(0, 0, 0, 0));

    if (checkInDate && !checkOutDate && bookedMaxDate && date > bookedMaxDate) {
      return "excluded-date";
    }

    if (
      checkInDate &&
      checkInDate < date &&
      isBlocked(date) && // Current date is blocked or excluded
      !isBlocked(previousDate) && // Previous date is not blocked or excluded
      !isBlocked(nextDate) // Next date is blocked or excluded
    ) {
      if (checkOutDate) {
        if (checkOutDate.getTime() === date.getTime()) {
          return "not-excluded-date";
        }
      } else {
        return "not-excluded-date";
      }
    }

    if (
      checkInDate &&
      checkInDate < date &&
      isBlocked(date) && // Current date is blocked or excluded
      !isBlocked(previousDate) && // Previous date is not blocked or excluded
      isBlocked(nextDate) // Next date is blocked or excluded
    ) {
      if (checkOutDate) {
        if (checkOutDate.getTime() === date.getTime()) {
          return "not-excluded-date";
        }
      } else {
        return "not-excluded-date";
      }
    }

    // Check if the date is part of the booked days (excluded dates)
    const isExcludedDate = bookedDaysArray.some(
      (blockedDate) => date.getTime() === blockedDate.getTime()
    );

    if (isExcludedDate) {
      return "excluded-date";
    }

    return "";
  };

  // Effect to monitor changes in checkInDate and checkOutDate
  useEffect(() => {
    const prevCheckIn = prevDatesRef.current.checkIn;
    const prevCheckOut = prevDatesRef.current.checkOut;

    // Update the ref with the new values for next comparison
    prevDatesRef.current = { checkIn: checkInDate, checkOut: checkOutDate };

    // If both dates are now set and they weren't both set before
    if (checkInDate && checkOutDate) {
      if (!prevCheckIn || !prevCheckOut) {
        // This is a newly completed date range selection, close the picker
        setIsDatesOpen(false);
      }
    }
  }, [checkInDate, checkOutDate, setIsDatesOpen]);

  useEffect(() => {
    if (!checkInDate && !checkOutDate) setBookedMaxDate(null);
  }, [checkInDate, checkOutDate, setBookedMaxDate]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      if (
        triggerRef.current &&
        !triggerRef.current.contains(target) &&
        calendarRef.current &&
        !calendarRef.current.contains(target)
      ) {
        console.log("Clicked outside, closing date picker");
        setIsDatesOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsDatesOpen]);

  useEffect(() => {
    if (bookedDays) setBookedDaysArray(bookedDays);
    else setBookedDaysArray([]);
  }, [bookedDays]);

  useEffect(() => {
    if (setIsDatesOpen) {
      setIsDatesOpen(isDatesOpen);
    }
  }, [isDatesOpen, setIsDatesOpen]);

  const renderCalendarContainer = (props: any) => {
    return (
      <div ref={calendarRef}>
        <div {...props}>{props.children}</div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={triggerRef}
        onClick={() => {
          setIsDatesOpen((prev) => {
            if (!prev) {
              datePickerRef.current?.setFocus();
            }
            return !prev;
          });
        }}
        className={`relative cursor-pointer ${
          isDatesOpen && "mobile:border-primaryB6"
        } mobile:border-[0.5px] mobile:w-full mobile:border-neutralN6 mobile:rounded-[2.5px] mobile:p-2.5 mobile:flex mobile:flex-col`}
      >
        {children}
        {(!checkInDate || !checkOutDate) && (
          <BodyText className="font-normal !text-[16px] text-neutralN6">
            add dates
          </BodyText>
        )}

        {checkInDate && checkOutDate && (
          <BodyText className="font-normal !text-[16px] text-primaryB7">
            {`${format(checkInDate, "dd MMM, yy")} - ${format(
              checkOutDate,
              "dd MMM, yy"
            )}`}
          </BodyText>
        )}
      </div>

      {isDatesOpen && (
        <DatePicker
          ref={datePickerRef}
          selected={checkInDate}
          //@ts-ignore
          onChange={onDateChange}
          //@ts-ignore
          startDate={checkInDate}
          //@ts-ignore
          endDate={checkOutDate}
          selectsRange
          placeholderText="add dates"
          dayClassName={dayClassName}
          popperPlacement="bottom-start"
          open={isDatesOpen}
          shouldCloseOnSelect={false}
          calendarContainer={renderCalendarContainer}
          onCalendarClose={() => setIsDatesOpen(false)}
        />
      )}
    </>
  );
};
