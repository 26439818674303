import { Skeleton } from "./ui/skeleton";

export const ListingsSkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className} w-full grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto`}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
        <Skeleton key={item} className="w-full h-[380px] !rounded-[5px]" />
      ))}
    </div>
  );
};
